<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 13"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <!-- fill="#017074"  -->
      <g
        :fill="color"
        ill-rule="nonzero"
      >
        <g>
          <path
            d="M19.576 7.053c-.065-.04-1.442-.888-3.184-1.172.276-1.733-.091-3.296-.115-3.393l-.117-.485-.499.039c-.075.005-1.66.138-3.217.926C11.65 1.425 10.455.392 10.38.328L10 .006l-.38.324c-.075.063-1.27 1.096-2.064 2.639C6 2.18 4.414 2.048 4.34 2.042l-.499-.04-.117.486c-.024.097-.391 1.66-.115 3.393C1.866 6.165.488 7.013.424 7.053L0 7.318l.193.46c.058.137 1.437 3.356 4.222 4.242.54.172 1.087.241 1.62.241 1.677 0 3.208-.689 3.896-1.051l.069.059.07-.06c.687.363 2.218 1.052 3.895 1.052.532 0 1.08-.07 1.62-.241 2.785-.886 4.164-4.105 4.222-4.241L20 7.318l-.424-.265zM4.77 10.901c-1.695-.539-2.805-2.261-3.26-3.104.51-.253 1.39-.626 2.381-.773.166.479.396.949.711 1.383.926 1.277 2.367 1.951 3.522 2.304-.949.322-2.196.559-3.354.19zm.782-3.183c-1.044-1.44-.915-3.483-.781-4.432.554.098 1.466.316 2.336.77-.155.497-.25 1.028-.25 1.58 0 1.564.759 2.946 1.481 3.909-.951-.307-2.082-.856-2.786-1.827zM10 9.684c-.665-.687-1.968-2.261-1.968-4.047 0-1.78 1.305-3.359 1.97-4.048.665.688 1.968 2.262 1.968 4.048 0 1.779-1.305 3.358-1.97 4.047zm3.144-4.047c0-.553-.095-1.084-.25-1.58.87-.456 1.783-.673 2.337-.771.135.947.266 2.986-.782 4.432-.704.971-1.835 1.52-2.786 1.827.722-.963 1.48-2.345 1.48-3.908zM15.23 10.9c-1.153.367-2.4.13-3.35-.192 1.154-.352 2.594-1.027 3.518-2.302.315-.434.545-.904.71-1.382.989.146 1.87.52 2.382.774-.453.842-1.558 2.561-3.26 3.102z"
            transform="translate(-196.000000, -835.000000) translate(196.000000, 835.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 16
    },
    variant: {
      type: String,
      default: 'light' // light, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'light') {
        return '#fafafa'
      }

      // Default: light
      return '#fafafa'
    })()

    const width = props.size
    const height = props.size // * (13 / 20)

    return {
      width,
      height,
      color,
    }
  }
})
</script>
