<template>
  <div style="height: 16px; width: 16px">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-gift"
    >
      <polyline id="id_106" points="20 12 20 22 4 22 4 12" style="stroke: rgb(255, 255, 255)"></polyline>
      <rect id="id_107" x="2" y="7" width="20" height="5" style="stroke: rgb(255, 255, 255)"></rect>
      <line id="id_108" x1="12" y1="22" x2="12" y2="7" style="stroke: rgb(255, 255, 255)"></line>
      <path id="id_109" d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z" style="stroke: rgb(255, 255, 255)"></path>
      <path id="id_110" d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z" style="stroke: rgb(255, 255, 255)"></path>
    </svg>
  </div>
</template>
