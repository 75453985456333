<template>
  <div class="pax-inline-selector">
    <SplButton
      variant="round"
      class="pax-inline-selector__btn"
      :class="{'no-pax-selected': !hasSelectedPax, 'light': light}"
      @click="subOneToPax"
    >
      -
    </SplButton>
    <span :class="{'no-pax-selected': !hasSelectedPax}">{{ selectedPaxValue }}</span>
    <SplButton
      variant="round"
      class="pax-inline-selector__btn"
      :class="{'light': light}"
      @click="addOneToPax"
    >
      +
    </SplButton>
  </div>
</template>

<script lang="ts">
import SplButton from '~/core/components/shared/form/SplButton.vue'

import { usePaxSelector } from '@/core/composable/spa-service/usePaxSelector'

export default defineNuxtComponent({
  components: {
    SplButton,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    increment: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const {
      hasSelectedPax,
      selectedPaxValue,

      addOneToPax,
      subOneToPax,
    } = usePaxSelector(ref([]), '0', props.increment)

    return {
      hasSelectedPax,
      selectedPaxValue,

      addOneToPax,
      subOneToPax,
    }
  },
})
</script>

<style lang="scss" scoped>
.pax-inline-selector {
  @apply flex;
  @apply items-center justify-center;
  @apply gap-4;

  @apply text-spl-dark;
  @apply font-bold;

  @apply py-2;
}

.no-pax-selected {
  @apply text-spl-gray-2;
}

.light {
  @apply bg-white;
}
</style>
