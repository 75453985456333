import { useSelectorToQueryParam } from "./useSelectorToQueryParam"

import { dateToYYYYMMDD, ensureDateFromYYYYMMDD } from '~/core/ts/util/date'

export function useDateRange() {
  const {
    optionSelected,
    pushQueryParam,
    onValueChanged,
  } = useSelectorToQueryParam({
    label: 'date-range',
    defaultValue: ''
  })

  const selectedDateRange = computed(() => {
    if (!optionSelected.value.value) {
      return [null, null]
    }

    const [fromRaw, toRaw] = optionSelected.value.value.split(',') as Array<string>

    return [
      fromRaw && fromRaw !== 'null' ? ensureDateFromYYYYMMDD(fromRaw) : null,
      toRaw && toRaw !== 'null' ? ensureDateFromYYYYMMDD(toRaw) : null,
    ]
  })

  const selectedDateFrom = computed(() => {
    const [from, to] = selectedDateRange.value

    return from
  })

  const selectedDateTo = computed(() => {
    const [from, to] = selectedDateRange.value

    return to
  })

  const hasValidDateRange = computed(() => {
    const [from, to] = selectedDateRange.value
    if (!from) {
      return false
    }

    if (!to) {
      return false
    }

    return true
  })

  function pushDateRangeInput(from: Date | null, to: Date | null) {
    const fromStr = from ? dateToYYYYMMDD(from) : 'null'
    const toStr = to ? dateToYYYYMMDD(to) : 'null'

    pushQueryParam(`${fromStr},${toStr}`)
  }

  function onDateRangeChanged(fn: VoidFunction) {
    onValueChanged(fn)
  }

  return {
    rawDateRange: optionSelected,

    hasValidDateRange,

    selectedDateRange,
    selectedDateFrom,
    selectedDateTo,

    pushDateRangeInput,

    // Eventos
    onDateRangeChanged,
  }
}
