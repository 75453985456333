<template>
  <div :class="`date-range-picker ${variant}`">
    <VueDatePicker
      v-model="innerValue"
      locale="es"
      range
      auto-apply
      :multi-calendars="multiCalendars"
      :enable-time-picker="false"
      :clearable="false"
      cancel-text="Cancelar"
      select-text="Aceptar"
      :min-date="new Date()"
      format="dd-MM-yyyy"
      hide-input-icon
      @update:model-value="onModelChange"
    />

    <div class="date-range-picker__selected-dates">
      <div class="date-range-picker__selected-dates__from-to">
        <div class="date-range-picker__selected-dates__label">
          <Lang
            by-key="departure"
            capitalize-first
          />
        </div>
        <div class="date-range-picker__selected-dates__single-date">
          <div>{{ selectedDateFromStr }}</div>
          <CalendarIcon />
        </div>
      </div>
      <div class="date-range-picker__selected-dates__from-to">
        <div class="date-range-picker__selected-dates__label">
          <Lang
            by-key="arrive"
            capitalize-first
          />
        </div>
        <div class="date-range-picker__selected-dates__single-date">
          <div>{{ selectedDateToStr }}</div>
          <CalendarIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import CalendarIcon from '../icons/Calendar.icon.vue'

import { useDateRange } from '~/core/composable/shared/useDateRange'

import { dateToDDMMYYY, datetoDDmm } from '~/core/ts/util/date'

export default defineNuxtComponent({
  components: {
    VueDatePicker,
    CalendarIcon,
  },
  props: {
    multiCalendars: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: () => 'regular', // regular, minimized
    },
    fullDates: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const innerValue = ref<Array<Date>>([])

    const {
      selectedDateRange,
      pushDateRangeInput,

      selectedDateFrom,
      selectedDateTo,

      onDateRangeChanged,
    } = useDateRange()

    onMounted(() => {
      if (selectedDateFrom.value) {
        innerValue.value = selectedDateRange.value as Array<Date>
      }
    })

    onDateRangeChanged(() => {
      if (selectedDateFrom.value) {
        innerValue.value = selectedDateRange.value as Array<Date>
      }
    })

    function onModelChange(value: Array<Date>) {
      emit('onUpdate', value)

      const [from, to] = value

      pushDateRangeInput(from, to)
    }

    const selectedDateFromStr = computed(() => {
      if (!selectedDateFrom.value) {
        return ''
      }

      return props.fullDates
        ? dateToDDMMYYY(selectedDateFrom.value)
        : datetoDDmm(selectedDateFrom.value)
    })

    const selectedDateToStr = computed(() => {
      if (!selectedDateTo.value) {
        return ''
      }

      return props.fullDates
        ? dateToDDMMYYY(selectedDateTo.value)
        : datetoDDmm(selectedDateTo.value)
    })

    return {
      innerValue,

      selectedDateFromStr,
      selectedDateToStr,

      onModelChange,
    }
  }
})
</script>

<style lang="scss">
:root {
  // --dp-border-radius: 12px;   /*Configurable border-radius*/
  --dp-cell-border-radius: 16px; /*Specific border radius for the calendar cell*/
  --dp-preview-font-size: 1rem;
}
.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #7f8563;
  --dp-primary-disabled-color: #d1d5bd;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
.dp__instance_calendar {
  // padding: 8px !important;
  @apply p-4;
}
.dp__input {
  @apply py-4;
  @apply hidden;
}

.dp__input_wrap {
  @apply w-full;
  height: 48px;

  @apply border-0;
  @apply cursor-pointer;
}

.date-range-picker {
  @apply relative;

  &__selected-dates {
    @apply absolute;
    top: 0;
    left: 0;

    @apply w-full;
    @apply h-full;

    @apply grid;
    @apply grid-cols-2;
    @apply gap-2;

    pointer-events: none;

    &__from-to {
      @apply flex;
      @apply flex-col;

      @apply gap-2;

      @apply items-center;
      @apply justify-center;
    }

    &__label {
      @apply text-center;
      @apply font-bold;

      @apply text-spl-dark;
    }

    &__single-date {
      @apply flex;
      @apply items-center;
      @apply justify-center;

      @apply gap-2;
    }
  }
}

.regular {}

.minimized {
  .date-range-picker__selected-dates__label {
    @apply hidden;
  }

  .date-range-picker__selected-dates__from-to {
    @apply border-1;
  }
}

</style>
