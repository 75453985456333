<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 17 17"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        :fill="color"
        fill-rule="nonzero"
      >
        <g>
          <path
            d="M13.74 12.985c.129.131.3.197.47.197.17 0 .339-.064.468-.193l1.732-1.715c.38-.377.59-.879.59-1.413 0-.533-.209-1.033-.587-1.41l-1.697-1.713c-.258-.26-.679-.263-.94-.005-.26.258-.262.679-.004.94l1.51 1.524H9.929c-.367 0-.664.298-.664.664 0 .367.297.664.664.664h5.351l-1.535 1.52c-.26.259-.263.68-.005.94z"
            transform="translate(-722 -601) translate(722 601)"
          />
          <path
            d="M14.344 1.328h-.864V.664c0-.367-.297-.664-.664-.664-.366 0-.664.297-.664.664v.664H9.131V.664C9.13.297 8.834 0 8.467 0c-.367 0-.664.297-.664.664v.664H4.814V.664C4.814.297 4.517 0 4.15 0c-.366 0-.664.297-.664.664v.664h-.83C1.192 1.328 0 2.52 0 3.984v10.36C0 15.808 1.192 17 2.656 17h11.688C15.808 17 17 15.808 17 14.344c0-.367-.297-.664-.664-.664-.367 0-.664.297-.664.664 0 .732-.596 1.328-1.328 1.328H2.656c-.732 0-1.328-.596-1.328-1.328V3.984c0-.732.596-1.328 1.328-1.328h.83v.664c0 .367.298.664.664.664.367 0 .664-.297.664-.664v-.664h2.989v.664c0 .367.297.664.664.664.367 0 .664-.297.664-.664v-.664h3.021v.664c0 .367.298.664.664.664.367 0 .664-.297.664-.664v-.664h.864c.732 0 1.328.596 1.328 1.328V5.48c0 .366.297.664.664.664.367 0 .664-.298.664-.664V3.984c0-1.464-1.192-2.656-2.656-2.656z"
            transform="translate(-722 -601) translate(722 601)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 17
    },
    variant: {
      type: String,
      default: 'light' // light, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#303030'
      }
      if (props.variant === 'light') {
        return '#ABABAB'
      }

      // Default: light
      return '#ABABAB'
    })()
    
    return {
      color,
    }
  }
})
</script>
